@mixin hide-text {
    text-indent: 150%;
    white-space: nowrap;
    overflow: hidden;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}
