#playlist, #publications {
    padding-top: 40px;

    li {
        position: relative;
        max-width: 500px;

        &:hover {
            .cover-artwork {
                transform: scale(1.15);
                opacity: 0.6;
            }

        }

        > div {
            background-color: white;
            box-sizing: content-box;
            padding-top: 100%;
            height: 0;
            overflow: hidden;

            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                background: linear-gradient(to bottom, rgba(31,46,67,0) 0%,
                rgba(31, 46, 67, 0.6) 75%, rgba(31,46,67,1.0) 100%);
                z-index: 1;
            }

        }

        a {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
            z-index: 3;
        }

        .track-meta {
            position: absolute;
            bottom: 0;
            width: 100%;
            color: white;
            z-index: 2;

            text-shadow: black 1px 1px 5px;
            padding: 10px 10px;

            .track-name {
                font-size: 16pt;
                font-weight: bold;
                margin-bottom: 6px;
            }

            .track-meta-row {
                p {
                    font-size: inherit !important;
                    display: inline !important;
                }

                font-size: 11pt;
                line-height: 1.2;
                margin-bottom: 2px;
            }

            .track-meta-row-label {
                color: #dddddd;
            }

            .track-artist, .track-album {
                font-style: italic;
            }

        }

        .cover-artwork {
            position: absolute;
            width: 100%;
            height: 100%;

            left: 0;
            top: 0;
            bottom: 0;
            right: 0;

            transition: transform 0.3s ease, opacity 0.3s;
        }

        margin-bottom: 40px;
    }
}

ul#publications {
    @extend %grid-container;

    padding: 0 !important;
    list-style-type: none !important;

    li {
        margin-top: 0 !important;
        padding: 0 10px !important;
    }
}
