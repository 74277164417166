/* Configuration */

$content-width: 1220px;
$container-max-width: 1220px;
$main-color: #1F2E43;
$main-color-light: #398BED;
$my-gray: #dddddd;

$media-mobile-max: 969px;
$media-desktop-min: 970px;
$gutter-half: 10px;

$playlist-breakpoint: 530px;

$social-icon-count: 6;
$social-icon-gutter: 15px;
$footer-color: $main-color;
$footer-text-color: $my-gray;
$social-size: 120px;

$clock-size: 35px;
$clock-border: 3px;
$minute-hand-length: 15px;
$hour-hand-length: 12px;
$hour-hand-weight: 3px;
$minute-hand-weight: 2px;
$center-size: 4px;
$clock-time-width: 105px;
/* End configuration */

@import 'reset';
@import 'typefaces';
@import 'unsemantic-vars';
@import 'unsemantic-ie-snap';
@import 'utils';
@include unsemantic-grid-placeholders;

@import 'main';
@import 'responsive';
@import 'syntax-highlighting';
