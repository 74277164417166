@font-face {
	font-family: 'Computer Modern Classical Serif Italic';
	src: url('../typefaces/cmunci.eot');
	src: url('../typefaces/cmunci.eot?#iefix') format('embedded-opentype'),
		 url('../typefaces/cmunci.woff') format('woff'),
		 url('../typefaces/cmunci.ttf') format('truetype'),
		 url('../typefaces/cmunci.svg#cmunci') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Computer Modern Serif';
	src: url('../typefaces/cmunrm.eot');
	src: url('../typefaces/cmunrm.eot?#iefix') format('embedded-opentype'),
		 url('../typefaces/cmunrm.woff') format('woff'),
		 url('../typefaces/cmunrm.ttf') format('truetype'),
		 url('../typefaces/cmunrm.svg#cmunrm') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Computer Modern Serif';
	src: url('../typefaces/cmunbx.eot');
	src: url('../typefaces/cmunbx.eot?#iefix') format('embedded-opentype'),
		 url('../typefaces/cmunbx.woff') format('woff'),
		 url('../typefaces/cmunbx.ttf') format('truetype'),
		 url('../typefaces/cmunbx.svg#cmunbx') format('svg');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Serif';
	src: url('../typefaces/cmunti.eot');
	src: url('../typefaces/cmunti.eot?#iefix') format('embedded-opentype'),
		 url('../typefaces/cmunti.woff') format('woff'),
		 url('../typefaces/cmunti.ttf') format('truetype'),
		 url('../typefaces/cmunti.svg#cmunti') format('svg');
	font-weight: normal;
	font-style: italic;
}


@font-face {
	font-family: 'Computer Modern Serif';
	src: url('../typefaces/cmunbi.eot');
	src: url('../typefaces/cmunbi.eot?#iefix') format('embedded-opentype'),
		 url('../typefaces/cmunbi.woff') format('woff'),
		 url('../typefaces/cmunbi.ttf') format('truetype'),
		 url('../typefaces/cmunbi.svg#cmunbi') format('svg');
	font-weight: bold;
	font-style: italic;
}


