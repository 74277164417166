* {
    box-sizing: border-box;
}

body {
    font-family: 'Computer Modern Serif', georgia, serif;
}

em, i {
    font-style: italic;
}

strong, b {
    font-weight: bold;
}

p {
    line-height: 130%;
}

a {
    color: $main-color;

    &:hover {
        text-decoration: none;
    }
}


header {
    background-color: $main-color;
    color: white;
    padding: 30px 0 0 0;

    h1 {
        .headshot {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            display: block;
            margin: 0 auto 15px;
            position: relative;
            background: transparent url('../images/headshot.jpg');
            background-size: 100% 100%;

            &::after {
                display: block;
                position: absolute;
                content: ' ';
                border-radius: 50%;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                background: transparent url('../images/headshot-cnn.jpg');
                background-size: 100% 100%;
                opacity: 0;
                transition: opacity 0.5s;
            }

            &:hover::after {
                opacity: 1;
            }
        }
        text-align: center;
        font-size: 36pt;
    }

    h2 {
        text-align: center;
        font-size: 16pt;
        margin-top: 7px;
        font-style: italic;
    }

    nav {
        max-width: $content-width;
        width: 100%;
        color: white;
        margin: 54px auto 0;

        ul {
            text-align: center;

            li {
                font-size: 0;
                display: inline-block;

                a { 
                    color: white;
                    display: inline-block;
                    text-decoration: none;
                    font-size: 13pt;
                    padding: 20px 45px;

                    transition: color 0.2s, background-color 0.2s;
                    position: relative;

                    &.current {
                        color: $main-color;
                        background-color: white;
                    }

                    &:before { 
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        right: 50%;
                        height: 5px;
                        background-color: white;
                        transition: left 0.3s ease, right 0.3s ease;
                    }

                    &:hover:before {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }

    }
}

#content {
    @extend %grid-container;
    max-width: $content-width;
    width: 100%;
    margin: 0 auto;
    padding-top: 85px;
}

footer > div {
    @extend %grid-container;
    max-width: $content-width;
    width: 100%;
    margin: 0 auto;
}

.introductory-paragraph, #latest-posts, #whereabouts, #social-main, #playlist li, #blog-main, #blog-sidebar  {
    @extend %grid-columns-all;
}

#blog-main, #blog-sidebar {
    @extend %grid-columns-push-pull;
}

#latest-posts h1, #whereabouts h1, section.page h1 {
    color: $main-color;
    font-size: 26pt;
}

section.page > h1 {
    @extend %grid-columns-all;
    font-size: 32pt;
    text-align: center;
}

section.page > h2 {
    @extend %grid-columns-all;
    font-size: 16pt;
    margin-top: 25px;
    text-align: center;
}

footer {
    background-color: $footer-color;
    margin-top: 95px;
    padding: 50px;

    #social-footer {
        padding-right: 30px;
        li {
            font-size: 14pt;
            margin-top: 7px;
            padding-right: 21px;
            background: url(../images/social/placeholder.svg) no-repeat;
            background-size: 14px auto;
            background-position: 100% 50%;
            text-align: right;

            a {
                color: $footer-text-color; 
                text-decoration: none;
                transition: color 0.2s;

                &:hover {
                    text-decoration: underline;
                }
            }

        }
    }

    #paragraph-footer {
        font-size: 16pt;
        text-align: center;
        padding: 0 20px;
        border-left: 1px solid $footer-text-color;
        border-right: 1px solid $footer-text-color;

        color: $footer-text-color;

        a {
            color: $footer-text-color;

            &:hover {
                text-decoration: none;
            }
        }
    }

    nav {
        padding-left: 30px;
        font-size: 16pt;
        li {
            margin-top: 10px;
            a {
                color: $footer-text-color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

footer > div > * {
    display: table-cell;
    vertical-align: middle;
}

footer > div {
    display: table;
}
