#social-main li {

    &.email a {
        background-color: #333333;

        &:hover:before {
            background-color: #555555;
        }

        &:after {
            background-size: 40% auto;
            background-image: url('../images/social/email.svg');
        }

        &:hover:after {
            background-size: 50% auto;
        }

    }


    &.bluesky a {
        background-color: #325569;

        &:hover:before {
            background-color: #5C9EC2;
        }

        &:after {
            background-size: 50% auto;
            background-image: url('../images/social/bluesky.svg');
        }

        &:hover:after {
            background-size: 60% auto;
        }

    }

    &.x a {
        background-color: #488f65;

        &:hover:before {
            background-color: #62C389;
        }

        &:after {
            background-size: 40% auto;
            background-image: url('../images/social/x.svg');
        }

        &:hover:after {
            background-size: 50% auto;
        }

    }

    &.linkedin a {
        background-color: #6e4542;

        &:hover:before {
            background-color: #B16F6A;
        }

        &:after {
            background-size: 35% auto;
            background-image: url('../images/social/linkedin.svg');
        }

        &:hover:after {
            background-size: 45% auto;
        }
    }

    &.github a { 
        background-color: #c98536;

        &:hover:before {
            background-color: #FFA844;
        }

        &:after {
            background-size: 42% auto;
            background-image: url('../images/social/github.svg');
        }

        &:hover:after {
            background-size: 52% auto;
        }

    }

    &.scholar a {
        background-color: #0A3463;

        &:hover:before {
            background-color: #0054B2;
        }

        &:after {
            background-size: 42% auto;
            background-image: url('../images/social/scholar.svg');
        }

        &:hover:after {
            background-size: 52% auto;
        }

    }

    &.codeforces a {
        background-color: #822d55;

        &:hover:before {
            background-color: #C74485;
        }

        &:after {
            background-size: 44% auto;
            background-image: url('../images/social/codeforces.svg');
        }

        &:hover:after {
            background-size: 54% auto;
        }
    }

    &.youtube a {
        background-color: #4a3b6d;
        &:hover:before {
            background-color: #8066BC;
        }

        &:after {
            background-size: 47% auto;
            background-image: url('../images/social/youtube.svg');
        }

        &:hover:after {
            background-size: 57% auto;
        }

    }

    &.spotify a {
        background-color: #B5183F;

        &:hover:before {
            background-color: #E60B42;
        }

        &:after {
            background-size: 42% auto;
            background-image: url('../images/social/spotify.svg');
        }

        &:hover:after {
            background-size: 52% auto;
        }
    }
}

#social-footer li {
    &.email {
        background-image: url('../images/social/email.svg');
    }

    &.bluesky {
        background-image: url('../images/social/bluesky.svg');
    }

    &.x {
        background-image: url('../images/social/x.svg');
    }

    &.linkedin {
        background-image: url('../images/social/linkedin.svg');
    }

    &.github { 
        background-image: url('../images/social/github.svg');
    }

    &.scholar {
        background-image: url('../images/social/scholar.svg');
    }

    &.codeforces {
        background-image: url('../images/social/codeforces.svg');
    }

    &.youtube {
        background-image: url('../images/social/youtube.svg');
    }

    &.spotify {
        background-image: url('../images/social/spotify.svg');
    }
}
