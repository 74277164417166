.experience {
    p, .years {
        line-height: 1.5em;
    }

    .years {
        width: 8em;
        display: inline-block;
        vertical-align: top;
        color: #aaa;
        margin-left: 15px;
    }

    .details {
        display: inline-block;
        vertical-align: top;

        .title {
            margin-bottom: 2px;
            color: #777;

            a {
                font-weight: bold;
                color: $main-color;
            }

            .description {
                font-weight: bold;
                color: $main-color;
            }
        }

        .role {
            margin-bottom: 2px;
            font-style: italic;
        }
    }
}

