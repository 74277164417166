/* Desktop */
@media screen and (min-width: $media-desktop-min) {
    @include unsemantic-grid-placeholders(desktop);

    .introductory-paragraph {
        @extend %desktop-grid-100;
    }

    #search-box-mobile {
        display: none;
    }

    #search-box-desktop {
        display: block;
    }

    section#blog-main {
        @extend %desktop-push-33;
    }

    #blog-sidebar {
        @extend %desktop-pull-66;
    }

    #blog-main article:nth-child(2) {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
    }

    section.page h1, section.page h2 {
        @extend %desktop-grid-100;
    }

    #latest-posts {
        @extend %desktop-grid-65;

        #post-list {
            position: relative;
            min-height: 550px;

            #see-more-posts {
                position: absolute;
                bottom: 25px;
                margin-left: -100px;
                left: 50%;
                width: 200px;
                margin-top: 20px;
            }
        }
    }

    #whereabouts {
        @extend %desktop-grid-35;

        > #location-container {
            min-height: 550px;
            position: relative;

            #map {
                height: 470px;
                position: absolute;
                bottom: 0;
            }
        }

    }

    #social-main {
        @extend %desktop-grid-100;
    }

    #social-footer, footer nav {
        width: 25%;
    }

    #paragraph-footer {
        width: 50%;
    }

    #playlist li {
        @extend %desktop-grid-25;
    }

    #publications li {
        @extend %desktop-grid-33;
    }

    section#blog-main {
        @extend %desktop-grid-66;
    }

    article#blog-main {
        @extend %desktop-grid-100;
    }

    #blog-sidebar {
        @extend %desktop-grid-33;
    }
}

@media screen and (min-width: $media-desktop-min) and (max-width: 1100px) {
    #publications li {
        width: 50%;
    }
}

/* Playlist 8x2 landscape */
@media screen and (min-width: $playlist-breakpoint) and (max-width: $media-mobile-max) {
    @include unsemantic-grid-placeholders(mobile-landscape);
    #playlist li {
        @extend %mobile-landscape-grid-50;
    }
    #publications li {
        @extend %mobile-landscape-grid-50;
    }
}

/* Publications landscape */
@media screen and (min-width: $playlist-breakpoint) and (max-width: 780px) {
    #publications li {
        width: 100%;
    }
}


/* Playlist 16x1 portrait */
@media screen and (max-width: $playlist-breakpoint - 1) {
    @include unsemantic-grid-placeholders(mobile-portrait);
    #playlist li {
        @extend %mobile-portrait-grid-100;
    }

    #publications li {
        @extend %mobile-portrait-grid-100;
    }
}


/* Mobile */
@media screen and (max-width: $media-mobile-max) {
    @include unsemantic-grid-placeholders(mobile);

    #content {
        width: 100%;
    }

    #search-box-mobile {
        display: block;
    }

    #search-box-desktop {
        display: none;
    }

    #blog-main article:nth-child(2) {
        padding-top: 40px;
        border-top: 1px solid #ccc;
        margin-top: 40px;
    }

    #blog-main article:nth-child(1) {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
    }

    #blog-sidebar {
        margin-top: 40px;
    }

    section.page h1, section.page h2 {
        @extend %mobile-grid-100;
    }


    header nav {
        padding: 15px 0 30px;
        position: relative;

        ul {
            li {
                display: block;
                width: 100%;
                a {
                    display: block;
                    margin: 0 auto;

                    &.current {
                        background-color: transparent;
                        color: white;
                    }

                    &:before {
                        content: none;
                    }

                    &:hover {
                        background-color: white;
                        color: $main-color;
                    }
                }
            }
        }

        &:before {
            content: '';
            display: block;
            height: 1px;
            top: 0;
            left: 25%;
            right: 25%;
            position: absolute;
            background-color: white;
        }
    }

    .introductory-paragraph {
        @extend %mobile-grid-100;
        text-align: left;
    }

    #latest-posts {
        @extend %mobile-grid-100;
        margin-top: 50px;

        #post-list #see-more-posts {
            margin: 40px auto 0;
            width: 200px;
        }

    }

    #whereabouts {
        @extend %mobile-grid-100;
        margin-top: 45px;
        
        #map {
            /* Google Maps shenanigans fix */
            position: relative;
        }
    }

    #social-main {
        @extend %mobile-grid-100;
        ul {
            max-width: $social-size * ($social-icon-count / 2);
            width: 100%;
            margin: 0 auto;
        }

        padding-top: 40px;

        li {
            margin-top: $social-icon-gutter;
            display: block;
            float: left;
            width: 100% / ($social-icon-count / 2);

            a {
                display: block;
            }

            &:nth-child(3n + 1) {
                clear: left;
            }

            &:nth-child(2n) {
                a {
                    margin-right: 0;
                }
            }
        }
    }

    article#blog-main p {
        text-align: left;
    }

    #playlist li, #publications li {
        margin-left: auto;
        margin-right: auto;
    }

    footer {
        padding-left: 20px;
        padding-right: 20px;
    }

    footer > div {
        display: block;
        width: 100%;
    }

    footer > div > * {
        display: block;
        width: 100%;
    }
    
    footer {
        #social-footer {
            text-align: center;

            li {
                display: inline-block;
                text-align: center;
                margin-left: 20px;
                margin-top: 10px;
                background-position: 0 50%;
                padding-right: 0;
                padding-left: 21px;

                &.bluesky {
                    background-position: 4px center;
                }
            }
        }

        #paragraph-footer {
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid $footer-text-color;
            border-left: 0px none;
            border-right: 0px none;
        }

        nav {
            padding-left: 0;
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid $footer-text-color;

            li {
                text-align: center;
            }
        }
    }

    #blog-sidebar {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 70px;
    }

    #blog-main, #blog-sidebar {
        @extend %mobile-grid-100;
    }

    .experience {
        .years {
            margin: 5px 0;
        }

        .years, .details {
            padding: 0 10px;
        }

        .details {
            margin-bottom: 10px;
        }
    }
}

